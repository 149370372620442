import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RequestOptions } from '@angular/http'
//import * as jwt_decode from 'jwt-decode';
import { environment } from '../../../../environments/environment';
import { ClaimsService } from '../../services/claims.service';

@Component({
  moduleId: module.id,
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit
{
  model: any = {};
  loading = false;
  returnUrl: string;
  //postAuth: string;
  //Declaring variables to retreive config values
  //config: Config;
  idpClientId: string;
  error: any;
  env: string;
  securityServiceUrl: string;
  i360SecurityServiceUrl: string;
  idpAuthCodeBaseUrl: string;
  idpA360ClientId: string;
  idpInvestor360ClientId: string;
  i360Idpadapterid: string;
  i360AuthParam: string;
  advisor360CFNIdpadapterid: string;
  advisor360MMLAuthParam: string;
  A360AdvisorLoginAuthParam: string;
  i360CfnUrl: string;
  i360MMLUrl: string;
  i360GWNUrl: string;
  i360LoginBaseUrl: string;
  advisor360MMLLoginBaseUrl: string;
  advisor360CFNLoginBaseUrl: string;

  advisor360ErrorUrl: string;
  i360ErrorUrl: string;
  
  errorUrl: string;
  private options = new RequestOptions({ withCredentials: true });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private claimsService: ClaimsService
  )
  {
    const hostname = window.location.hostname;

    ///*   Get the config file content */
    this.http.get<JSON>("assets/config-20240806.json")
      .toPromise()
      .then((allData: JSON) => {
        const data = allData[hostname];
        this.env = data["environment"];
        this.securityServiceUrl = data["securityServiceUrl"];   
        this.i360SecurityServiceUrl = data["i360SecurityServiceUrl"];      
        this.idpAuthCodeBaseUrl = data["idpAuthCodeBaseUrl"];
        this.idpA360ClientId = data["idpA360ClientId"];
        this.idpInvestor360ClientId = data["idpInvestor360ClientId"];
        this.i360Idpadapterid = data["i360Idpadapterid"];
        this.i360AuthParam = data["i360AuthParam"];
        this.advisor360CFNIdpadapterid = data["advisor360CFNIdpadapterid"];
        this.advisor360MMLAuthParam = data["advisor360MMLAuthParam"];
        this.A360AdvisorLoginAuthParam = data["A360AdvisorLoginAuthParam"];
        this.i360CfnUrl = data["i360CfnUrl"];
        this.i360MMLUrl = data["i360MMLUrl"];
        this.i360GWNUrl = data["i360GWNUrl"];
        this.i360LoginBaseUrl = data["i360LoginBaseUrl"];
        this.advisor360MMLLoginBaseUrl = data["advisor360MMLLoginBaseUrl"];
        this.advisor360CFNLoginBaseUrl = data["advisor360CFNLoginBaseUrl"];
        this.advisor360ErrorUrl = data["advisor360ErrorUrl"];
        this.i360ErrorUrl = data["i360ErrorUrl"];
    
        console.log("Environment pointed to: " + this.env);


        //Url used by Ping to send back the authcode and it should be this Login app url
        var currentUrl = window.location.origin + window.location.pathname;
        //  currentUrl = currentUrl.toLowerCase();
        ////if not redirect from ping then set the post auth redirect url i.e the first time this login app url is accessed
        //if (document.referrer.indexOf("ping") < 0) {
        //  postAuthRedirectUrl = document.referrer;
        //}
        console.log("currentUrl:" + currentUrl);

        var pfidpadapterid = null;
        var AuthParam = null;
        var tenant = null;
        var isI360 = "false";
        this.idpClientId = this.idpA360ClientId;
        this.errorUrl = this.advisor360ErrorUrl;

        //Set the ping form to be used
        if (currentUrl.indexOf("/AutoLogin") >= 0) {
          AuthParam = "Azure-AD";
          console.log("Setting authParam for AutoLogin: " + AuthParam);
        }
        else if (currentUrl.indexOf("/A360AdvisorLogin") >= 0) {
          AuthParam = this.A360AdvisorLoginAuthParam;
          console.log("Setting authParam to A360AdvisorLoginAuthParam: " + this.A360AdvisorLoginAuthParam);
        }
        //Investor 360 ping form
        else if (currentUrl.indexOf(this.i360LoginBaseUrl) >= 0) {
          pfidpadapterid = "i360Idpadapterid";
          AuthParam = this.i360AuthParam;
          this.idpClientId = this.idpInvestor360ClientId;
          this.securityServiceUrl = this.i360SecurityServiceUrl;
          isI360 = "true";
          this.errorUrl = this.i360ErrorUrl;
          console.log("Setting authParam to i360LoginBaseUrl: " + this.i360LoginBaseUrl);        
        }
        //Advisor 360 ping form
        else if (currentUrl.indexOf(this.advisor360CFNLoginBaseUrl) >= 0) {
          pfidpadapterid = "advisor360CFNIdpadapterid";
          tenant = "CFN";
          AuthParam = "Kerberos";
          console.log("Setting authParam to advisor360CFNLoginBaseUrl: " + this.advisor360CFNLoginBaseUrl);
        }
        //Advisor 360 MML ping form
        else if (currentUrl.indexOf(this.advisor360MMLLoginBaseUrl) >= 0) {
          tenant = "MML";
          AuthParam = this.advisor360MMLAuthParam;
          console.log("Setting authParam to advisor360MMLAuthParam: " + this.advisor360MMLAuthParam);

        }       
        else {
          pfidpadapterid = "i360Idpadapterid"
          console.log("Setting pfidpadapterid to i360Idpadapterid");
        }

        console.log("AuthParam: " + AuthParam);
        console.log("isI360: " + isI360);

        var stateUrl = this.route.snapshot.queryParamMap.get("stateUrl");
        var code = this.route.snapshot.queryParamMap.get("code");

        console.log("stateUrl: " + stateUrl);
        console.log("code: " + code);

        //Get Host name
        function getHost(s: string) {
          console.log("string s: " + s);
          var url = new URL(s).hostname.split('.')
          return url.slice(-2).join('.');
        };

        if (code != null) {

          //state returned back by Ping
          stateUrl = this.route.snapshot.queryParamMap.get("state");
          console.log("state: " + stateUrl);

          var postAccessCodeRedirectUrl = null;
          var postAuthRedirectUrl = null;

          //postAccessCodeRedirectUrl = this.route.snapshot.queryParamMap.get("postAccessCodeRedirectUrl");
          //postAuthRedirectUrl = this.route.snapshot.queryParamMap.get("postAuthRedirectUrl");

          //Extract the Redirect URL from StateUrl param
          //if (stateUrl.search("postAccessCodeRedirectUrl") != -1) {
          //  postAccessCodeRedirectUrl = stateUrl.substring(stateUrl.lastIndexOf("=") + 1);
          //}

          //Extract the Redirect URL from StateUrl param
          if (stateUrl.search("postAuthRedirectUrl") != -1) {
            postAuthRedirectUrl = stateUrl.substring(stateUrl.indexOf("=") + 1);
            // postAuthRedirectUrl = stateUrl.substring(stateUrl.lastIndexOf("=") + 1);
          }

          

          var apiQueryString = "?accessCode=" + code + "&client_id=" + this.idpClientId + "&isI360=" + isI360 + "&redirectUri=" + currentUrl;
          console.log("apiQueryString: " + apiQueryString);

         

          if (postAccessCodeRedirectUrl != null) {
            console.log("postAccessCodeRedirectUrl: " + postAccessCodeRedirectUrl)
            window.location.href = postAccessCodeRedirectUrl + apiQueryString;
          }
          else {
            this.http.post(this.securityServiceUrl + '/Login/GetToken' + apiQueryString, null, { observe: 'response' }).subscribe(result => {
              if (result.body) {
               
                //Get Authorization header and set as token
                //On navigate the auth guard will check for valid token
                var access_token = result.headers.get("access_token");
                sessionStorage.setItem("access_token", access_token);

                var claims_token = result.headers.get("claims_token");
                sessionStorage.setItem("claims_token", claims_token);

                var refresh_token = result.headers.get("refresh_token");
                sessionStorage.setItem("refresh_token", refresh_token);

                let jsonStringResult = JSON.stringify(result);
               // console.log(jsonStringResult);

                let jsonResult = JSON.parse(jsonStringResult);
                //console.log(jsonResult);

               // console.log(jsonResult.body);
                //var userId = this.claimsService.getClaimValueByClaimType("UserId");
                var userId = jsonResult.body.userId;
               // console.log("userId: " + userId);
               // sessionStorage.setItem("userId", userId);

                var tenant = jsonResult.body.tenantName;
               // console.log("TenantName: " + tenant);
               // sessionStorage.setItem("TenantName", tenant);

                //------- To check the allowed domains for postauthredirecturl" -------
                console.log("postAuthRedirectUrl is : " + postAuthRedirectUrl);
               
                

                if (postAuthRedirectUrl != null && postAuthRedirectUrl != "")
                {
                  postAuthRedirectUrl = decodeURIComponent(postAuthRedirectUrl);
                  console.log("postAuthRedirectUrl -- " + postAuthRedirectUrl);
                  var i360LoginBaseUrlDomain = getHost(this.i360LoginBaseUrl);
                  var advisor360MMLLoginBaseUrlDomain = getHost(this.advisor360MMLLoginBaseUrl);
                  //allowed domains
                  var allowedDomains: string[] = [i360LoginBaseUrlDomain, advisor360MMLLoginBaseUrlDomain];
                  console.log("allowedDomains: " + allowedDomains);

                  //get host name of postAuthRedirectUrl
                  var postAuthRedirectUrlhost = getHost(postAuthRedirectUrl);
                  console.log("postAuthRedirectUrlhost : " + postAuthRedirectUrlhost);
                  
                  if (allowedDomains.indexOf(postAuthRedirectUrlhost) == -1) {
                    console.log(postAuthRedirectUrlhost + " domain is not whitelisted.");
                    console.log("Setting postAuthRedirect to null.");
                    postAuthRedirectUrl = null;
                  }
                  else {
                    console.log(postAuthRedirectUrlhost + " domain is allowed.");
                  }
                }
                else {
                  console.log("postAuthRedirectUrl is null!!");
                }
          //------- --------------------------------------------------- -------

                const splitCookie = document.cookie.split(';');

                //For I360 there will be a central Investor360 login site. Post login based on tenant claim will be redirected to appropriate I360 site
                if (currentUrl.indexOf(this.i360LoginBaseUrl) >= 0 && postAuthRedirectUrl == null) {

                  this.errorUrl = this.i360ErrorUrl;
                  //var tenant = this.claimsService.getClaimValueByClaimType("tenant");
                  console.log("tenant: "+ tenant);

                  //for now default tenant to CFN
                  //tenant = "CFN";

                  if (tenant == "CFN") {
                    postAuthRedirectUrl = this.i360CfnUrl;
                  }
                  else if (tenant == "MML") {
                    postAuthRedirectUrl = this.i360MMLUrl;
                  }
                  else if (tenant == "GWN") {
                    postAuthRedirectUrl = this.i360GWNUrl;
                  }

                  console.log("tenant: " + tenant);
                  console.log("postAuthRedirectUrl: " + postAuthRedirectUrl)
                  window.location.replace(postAuthRedirectUrl);

                }
                else if (postAuthRedirectUrl != null) {
                  console.log("postAuthRedirectUrl: " + postAuthRedirectUrl)
                  window.location.replace(postAuthRedirectUrl);
                }
                else {
                  console.log("Navigate to this.router.navigate([stateUrl]): " + stateUrl);
                  this.router.navigate([stateUrl]);
                  //window.location.replace(stateUrl);
                }

              }
              else {
                var combinedUrl: string;
                combinedUrl = this.idpAuthCodeBaseUrl + "&AuthParam=" + AuthParam + "&client_id=" + this.idpClientId + "&pfidpadapterid=" + pfidpadapterid + "&isI360=" + isI360 + "&redirect_uri=" + currentUrl + "&state= null";
                console.log("Navigate to window.location.href = combinedUrl: " + combinedUrl);
                window.location.href = combinedUrl;
              }
            }, error => {
              //ToDo - handle error 
              //console.error(error);
                console.log("Something went wrong.")
                this.model.invalidLoginMsg = "Processing Error";
                window.location.replace(this.errorUrl);
                this.loading = false;
            }
            );
          }
        }
        else {
          //Get the code from PING
          console.log("Ping call - to retreive code");
          var tempUrl: string;
          tempUrl = this.idpAuthCodeBaseUrl + "&AuthParam=" + AuthParam + "&client_id=" + this.idpClientId + "&pfidpadapterid=" + pfidpadapterid + "&isI360=" + isI360 + "&redirect_uri=" + currentUrl + "&state= null" ;
          console.log("Navigate to window.location.href = tempUrl : " + tempUrl);
          window.location.href = tempUrl;
        }

        //ending the promise
      })
      .catch(function () {
        console.error("Failed to load the config values.")
      });
  }
  ngOnInit()
  {
    console.log("ngOnInit");
   //this.postAuth = this.route.snapshot.queryParams['postAuthRedirectUrl'];
    //console.log("this.postAuth" + this.postAuth);
    //get return url from route parameters or default to '/'   
    console.log("Navigate to returnUrl : " + this.route.snapshot.queryParams['returnUrl']);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

}


