import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './shared/components/login/login.component';
import { ImpersonateComponent } from './shared/components/impersonate/impersonate.component';
import { ImpersonateModule } from './shared/components/impersonate/impersonate.module';
import { AuthGuard } from './shared/guards/auth.guard';
import * as int from './shared/interceptors/WinAuthInterceptor';
import { ClaimsService } from './shared/services/claims.service';
// import { LoginModule } from '../../public_api';
import { LoginModule } from './shared/components/login/login.module';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'AutoLogin', component: LoginComponent },
  { path: 'A360AdvisorLogin', component: LoginComponent},
  {
    path: '', canActivate: [AuthGuard], children:
      [

        { path: 'home', component: HomeComponent, pathMatch: 'full' },
        { path: 'impersonate', component: ImpersonateComponent },


        //{ path: 'fetch-data', component: FetchDataComponent },
      ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ImpersonateComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    LoginModule,
    RouterModule.forRoot(routes),
    RouterModule
    // ImpersonateModule
    ////RouterModule.forRoot([
    //  { path: '', component: HomeComponent, pathMatch: 'full' },
    //  { path: 'counter', component: CounterComponent },
    //  { path: 'fetch-data', component: FetchDataComponent },
    //  { path: 'login', component: LoginComponent }
    //])
  ],
  exports: [
    ImpersonateComponent
    ],
  providers: [   {
      provide: HTTP_INTERCEPTORS,
      useClass: int.WinAuthInterceptor,
      multi: true
  },
    AuthGuard,
    ClaimsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
