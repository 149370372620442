import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { RequestOptions } from '@angular/http'
//import * as jwt_decode from 'jwt-decode';
import { environment } from '../../../../environments/environment';

@Component({
    moduleId: module.id,
    templateUrl: 'impersonate.component.html'
})

export class ImpersonateComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
   // private options = new RequestOptions({ withCredentials: true });

    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private http: HttpClient
    ) {

      //  //TO Do - move this to separate auth service
      //this.http.get<any>(environment.securityServiceUrl + '/Impersonate', { observe: 'response' }).subscribe(result => {
      //    if (result.body) {

      //            //Get Authorization header and set as token
      //            //On navigate the auth guard will check for valid token
      //            var token = result.headers.get("access_token");
      //            sessionStorage.setItem("token", token);

      //            this.router.navigate([this.returnUrl]);
      //          }
      //  }, error => console.error(error));

    }

    ngOnInit() {

        //this.route.queryParams.subscribe(params => { this.returnUrl = params['returnUrl']; });

        //get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    impersonate() {
      this.loading = true;
      var impersonateeUserName = this.model.impersonateeUsername;
      var impersonateeUserId = this.model.impersonateeUserId;

      //let headers = new HttpHeaders();
      //headers = headers.append("Authorization", "Basic " + btoa(username + ":" + userId));
      //headers = headers.append("Content-Type", "application/x-www-form-urlencoded");

      var apiQueryString = "?impersonateeUserName=" + impersonateeUserName + "&impersonateeUserId=" + impersonateeUserId;

      //TO Do - move this to separate auth service
      this.http.post(environment + '/Impersonate/Impersonate' + apiQueryString, null, {observe: 'response' }).subscribe(result => {
        if (result.body) {

           
          //Get Authorization header and set as token
          //On navigate the auth guard will check for valid token
          //var token = result.headers.get("Authorization");
          //sessionStorage.setItem("token", token);

          var token = result.headers.get("access_token");
          sessionStorage.setItem("token", token);

          this.router.navigate([this.returnUrl]);
        }
        else {
          this.model.invalidLoginMsg = "Username or password is incorrect";
          this.loading = false;
        }
      }, error => {
            //console.error(error);
            this.model.invalidLoginMsg = "Processing Error";
            this.loading = false;
          }
      );

    }
}

