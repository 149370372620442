import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import * as jwt_decode_ from 'jwt-decode';
const jwt_decode = jwt_decode_

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    //var token = sessionStorage.getItem("access_token");

    var documentCookie = accessCookie("CFNSession");

    // Given a cookie key "cookieName", returns the value of
    // the cookie or "", if the key is not found.
    function accessCookie(cookieName) {
      var name = cookieName + "=";
      var allCookieArray = document.cookie.split(';');
      for (var i = 0; i < allCookieArray.length; i++) {
        var temp = allCookieArray[i].trim();
        if (temp.indexOf(name) == 0)
          return temp.substring(name.length, temp.length);
      }
      return "";
    }

    console.log("Getting cookie back from DocumentCookie: " + documentCookie);

    if (documentCookie != null && documentCookie != "") {
      // logged in so return true
      console.log("DocumentCookie Value : " + documentCookie);
      return true;
    }

    ////ToDo - Validate token server side
    ////if (token != null)
    ////{
    ////   logged in so return true
    ////  return true;
    ////}

    //// not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { stateUrl: state.url } });

    //this.router.navigateByUrl("https://cr02ap-poc001clx.commonwealth.com:9031/as/authorization.oauth2?client_id=ac_client&response_type=code&access_token_manager_id=jwt&pfidpadapterid=ad..HTMLFormAdapterSQL");

    return false;
  }



}
