import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/operator/do';
import { tap } from "rxjs/operators";
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

//import { Observable } from 'rxjs';

@Injectable()
export class WinAuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private route: ActivatedRoute) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

 
    if (sessionStorage.getItem("access_token") != null) {
      req = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + sessionStorage.getItem("access_token")),
        withCredentials: true
      });
    }
    else {
      req = req.clone({
        withCredentials: true
      });
    }
    
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {

      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {

          //redirect to login page if http call returns 401 either due to token expiration or some other reason
          this.router.navigate(['/login'], { queryParams: { returnUrl: this.route.url} });

        }
      }
    }));
    
  }
}
