import { Component, Injectable } from '@angular/core';
import * as jwt_decode_ from 'jwt-decode';
//import jwt_decode from 'jwt-decode';
const jwt_decode = jwt_decode_;
@Injectable()
export class ClaimsService {

  constructor() { }


  claimValueExists(claimType: string, claimValue: string): boolean {
      var isMatch = false;

      var decodedToken = jwt_decode(sessionStorage.getItem("claims_token"));

      if (decodedToken[claimType].indexOf(claimValue) > -1)
        isMatch = true;

      return isMatch;

  }

  
  getClaimValueByClaimType(claimType: string): any {

    var decodedToken = jwt_decode(sessionStorage.getItem("claims_token"));

    var test = decodedToken[claimType];

    return test;

  }

  roleClaimExists(roleName: string): boolean {

    return this.claimValueExists("Role", roleName);

  }

}
