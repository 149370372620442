import { Component, OnInit} from '@angular/core';
import { ClaimsService } from '../shared/services/claims.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  public userName;
  public showUserDetails = false;

  externalUrl: SafeUrl;

  constructor(private claimsService: ClaimsService, private http: HttpClient, private sanitizer: DomSanitizer) {

    //var commLinkRole = this.claimsService.roleClaimExists("community_link_application_granted");

  }

  ngOnInit() {
 
    //var tenant = claimsService.getClaimValueByClaimType("Tenant");
    //TODO - Modify URL at runtime based on tenant retrieved above from claims

    //this.externalUrl = this.sanitizer.bypassSecurityTrustResourceUrl("http://localhost:64925/");

    //this.externalUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://qa.investor360.net/");

    //this.externalUrl = "https://qa.investor360.net/";
  }

  public getUserDetails() {

    var apiQueryString = "?userId=" + this.claimsService.getClaimValueByClaimType("UserId");

    //this.http.get<any>(environment.securityServiceUrl + '/UserAccess/GetUserDetails' + apiQueryString, { observe: 'response' }).subscribe(result => {
    //  //this.userName = result.body;
    //  //this.showUserDetails = true;
    //}, error => console.error(error));

  }
}
